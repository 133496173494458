<!-- 余额详情下的商户信息展示 -->
<template>
  <div>
    <a-spin :spinning="licenseeInfoLoading">
      <a-card :bordered="false">
        <DescriptionList>
          <div class="detailStyle" style="margin-left: 8px">
            <Description term="门店编号" class="first">
              <label>{{ licenseeInfo.facilityCode }}</label>
            </Description>
            <Description term="门店名称" class="first">
              <label>{{ licenseeInfo.facilityName }}</label>
            </Description>
            <Description term="档口编号" class="first">
              <label>{{ licenseeInfo.kitchenCode }}</label>
            </Description>
            <Description term="店铺名称" class="first">
              <label>{{ licenseeInfo.storeName }}</label>
            </Description>
            <Description term="客户签约主体">
              <label>{{ licenseeInfo.licenseeEntityName }}</label>
            </Description>
            <Description term="客户合同">
              <a @click="viewContractDetail">
                <label>{{ licenseeInfo.contractCode }}</label>
              </a>
            </Description>
          </div>
        </DescriptionList>
      </a-card>
    </a-spin>
  </div>
</template>
<script>
import axios from "axios";
import DescriptionList from "@/components/DescriptionList";

const { Description } = DescriptionList;

export default {
  name: "licenseeInfo",
  components: {
    DescriptionList,
    Description,
  },
  props: {
    contractId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      licenseeInfo: {},
      licenseeInfoLoading: false,
    };
  },
  methods: {
    // 初始化
    init() {
      this.loadLicenseeInfo();
    },

    loadLicenseeInfo() {
      this.licenseeInfoLoading = true;

      axios({
          url: this.$endpoints.CONTRACT_GET_CONTRACT_DETAIL,
          method: "GET",
          params: {
            contractId: this.contractId,
          }
        })
        .then(res => {
          if (res.data.success) {
            this.licenseeInfo.facilityCode = res.data.obj.facilityCode;
            this.licenseeInfo.facilityName = res.data.obj.facilityName;
            this.licenseeInfo.kitchenCode = res.data.obj.signKitchen;
            this.licenseeInfo.storeName = res.data.obj.storeName;
            this.licenseeInfo.licenseeEntityName = res.data.obj.signEntity;
            this.licenseeInfo.contractCode = res.data.obj.contractCode;
          }
          else {
            this.$message.error("获取商户信息失败，错误信息：" + res.data.returnMsg, 3);
          }

          this.licenseeInfoLoading = false;
        })
        .catch(error => {
          this.$message.error("获取商户信息店出错，错误信息：" + error.message, 3);
          this.licenseeInfoLoading = false;
        });
    },

    viewContractDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: {
          contractDetailsId: this.contractId,
        }
      });
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less">
</style>
